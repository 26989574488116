import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _defineProperty from "/gva_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters, mapMutations } from "vuex";
import AsideComponent from "@/view/layout/aside/asideComponent";
export default {
  name: "Aside",
  data: function data() {
    return {
      active: "",
      isCollapse: false
    };
  },
  methods: _objectSpread(_objectSpread({}, mapMutations("history", ["addHistory"])), {}, {
    selectMenuItem: function selectMenuItem(index, _, ele) {
      var query = {};
      var params = {};
      ele.route.parameters && ele.route.parameters.map(function (item) {
        if (item.type == "query") {
          query[item.key] = item.value;
        } else {
          params[item.key] = item.value;
        }
      });
      if (index === this.$route.name) return;
      if (index.indexOf("http://") > -1 || index.indexOf("https://") > -1) {
        window.open(index);
      } else {
        this.$router.push({
          name: index,
          query: query,
          params: params
        });
      }
    }
  }),
  computed: _objectSpread({}, mapGetters("router", ["asyncRouters"])),
  components: {
    AsideComponent: AsideComponent
  },
  created: function created() {
    var _this = this;
    this.active = this.$route.name;
    var screenWidth = document.body.clientWidth;
    if (screenWidth < 1000) {
      this.isCollapse = !this.isCollapse;
    }
    this.$bus.on("collapse", function (item) {
      _this.isCollapse = item;
    });
  },
  watch: {
    $route: function $route() {
      this.active = this.$route.name;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$bus.off("collapse");
  }
};