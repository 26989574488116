export default {
  name: 'MenuItem',
  props: {
    routerInfo: {
      default: function _default() {
        return null;
      },
      type: Object
    }
  }
};