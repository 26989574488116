import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      "popper-append-to-body": false,
      index: _vm.routerInfo.name
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    class: "el-icon-" + _vm.routerInfo.meta.icon
  }), _vm._v(" "), _c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$t("route.".concat(_vm.routerInfo.name))))])]), _vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };